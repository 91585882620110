<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table stripe pagination max-items="50" search :data="prescripteurs">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 md:mb-0" @click="$router.push('/apps/prescripteur/prescripteur-add')">Nouveau Prescripteur</vs-button>
              </template>
              <template slot="thead">
                <vs-th sort-key="numero">
                  N°
                </vs-th>
                <vs-th sort-key="NomPresc">
                  Nom
                </vs-th>
                <vs-th sort-key="Adresse.TelPresc">
                  Telephone
                </vs-th>
                <vs-th sort-key="Ristourne">
                  Ristourne
                </vs-th>
                <vs-th sort-key="taux">
                  Taux
                </vs-th>

                <vs-th >
                  Actions
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td :data="data[indextr]">
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="data[indextr].NomPresc">
                    {{data[indextr].NomPresc}}
                  </vs-td>

                  <vs-td :data="data[indextr].Adresse.TelPresc">
                    {{data[indextr].Adresse.TelPresc}}
                  </vs-td>

                  <vs-td :data="data[indextr].Ristourne">
                    <template v-if="data[indextr].Ristourne == true">
                      <vs-chip class="ag-grid-cell-chip" color="danger">
                        oui
                      </vs-chip>
                    </template>
                    <template v-else>
                      <vs-chip class="ag-grid-cell-chip" color="success">
                        non
                      </vs-chip>
                    </template>
                  </vs-td>

                  <vs-td :data="data[indextr].taux">
                    <template v-if="data[indextr].taux <= 30">
                      <vs-chip class="ag-grid-cell-chip" color="danger">
                        {{data[indextr].taux}} %
                      </vs-chip>
                    </template>
                    <template v-else-if="data[indextr].taux < 50">
                      <vs-chip class="ag-grid-cell-chip" color="warning">
                        {{data[indextr].taux}} %
                      </vs-chip>
                    </template>
                    <template v-else-if="data[indextr].taux >= 50">
                      <vs-chip class="ag-grid-cell-chip" color="success">
                        {{data[indextr].taux}} %
                      </vs-chip>
                    </template>

                  </vs-td>

                  <vs-td :data="data[indextr]._id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updatePrescripteurShowForm(data[indextr])" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selected:[],
      prescripteurId: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.libelle !== ''
    },
    prescripteurs () {
      return this.$store.state.prescripteur.prescripteurs
    }
  },

  methods: {
    getPrescripteurs () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .then(() => {

        })
        .catch(err => { console.log(err) })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    updatePrescripteurShowForm (data) {
      this.$router.push(`/apps/prescripteur/prescripteur-edit/${data._id}`).catch((err) => { console.log(err) })
    },
    confirmDeleteRecord (data) {
      this.prescripteurId = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmation de suppression',
        text: `Voulez-vous supprimer ce prescripteur: "${data.NomPresc} ${data.PrenomPresc}"`,
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$store.dispatch('prescripteur/removePrescripteur', this.prescripteurId)
        .then(() => {
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'Prescripteur supprimé',
        text: 'Le prescripteur selectionné a été supprimé avec success'
      })
    }
  },
  created () {
    this.getPrescripteurs()
  }
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>

